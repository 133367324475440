<header>
    <div class="px-3 py-2 border-bottom layout-header">
      <div class="container-fluid">
        <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <a href="/" class="logo logo-header">
            <img src="assets/logo_shadow.png" class="logo-img" alt="Senosia Logo">
            <span class="logo-text">Senosia</span>
          </a>

          <ul class="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
            <li>
              <a href="#" class="nav-link text-secondary d-flex flex-column">
                <i class="bi bi-nav bi-house d-block mx-auto mb-1"></i>
                Home
              </a>
            </li>
            <li>
              <a href="#" class="nav-link text-white d-flex flex-column">
                <i class="bi bi-nav bi-speedometer d-block mx-auto mb-1"></i>
                Dashboard
              </a>
            </li>
            <li>
              <a href="#" class="nav-link text-white d-flex flex-column">
                <i class="bi bi-nav bi-table d-block mx-auto mb-1"></i>
                Orders
              </a>
            </li>
            <li>
              <a href="#" class="nav-link text-white d-flex flex-column">
                <i class="bi bi-nav bi-grid d-block mx-auto mb-1"></i>
                Products
              </a>
            </li>
            <li>
              <a href="#" class="nav-link text-white d-flex flex-column">
                <i class="bi bi-nav bi-people d-block mx-auto mb-1"></i>
                Customers
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="px-3 py-2 border-bottom mb-3">
      <div class="container-fluid d-flex flex-wrap justify-content-end">

        <div class="text-end">
          <button type="button" class="btn btn-light text-dark me-2">Login</button>
          <button type="button" class="btn btn-primary">Sign-up</button>
        </div>
      </div>
    </div>
  </header>