import { Route } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

export const appRoutes: Route[] = [
    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full',
      },
    //   {
    //     path: 'products',
    //     loadComponent: () =>
    //       import('@myngapp/products').then((m) => m.ProductsComponent),
    //   },
    //   {
    //     path: 'orders',
    //     loadComponent: () =>
    //       import('@myngapp/orders').then((m) => m.OrdersComponent),
    //   },
];
